import React, { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import ExpertVideoBox from "../Products/ExpertVideoBox"

const ProductExpertSlider = ({ data }) => {
  const [modalOpen, setmodalOpen] = useState(false)
  const [videoOpen, setvideoOpen] = useState(null)
  const handleVideo = i => {
    if (data[i] && data[i].video_url) {
      setmodalOpen(!modalOpen)
      const videoUrl = new URL(data[i].video_url)
      videoUrl.searchParams.set("autoplay", 1)
      setvideoOpen(videoUrl.href)
    }
  }

  const modalOpenUrlParams = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const filterParam = urlParams.get("play_video")
    if (data && filterParam) {
      const videoUrlIndex = data.findIndex(e =>
        e.video_url.includes(filterParam)
      )
      handleVideo(videoUrlIndex)
    }
  }

  useEffect(() => {
    modalOpenUrlParams()
  }, [])

  return typeof window !== "undefined" ? (
    <ProductExpertWrapper>
      <ExpertVideoBox
        open={modalOpen}
        video={videoOpen}
        closeHandle={() => setmodalOpen(false)}
      />
      {data.map((item, index) => {
        return (
          item.name &&
          item.text && (
            <ExpertBox key={index.toString()}>
              {item.image && (
                <ExpertImage>
                  <Image className="lazyload" data-src={item.image} />
                </ExpertImage>
              )}

              <ExpertInfo>
                <BoxTitle dangerouslySetInnerHTML={{ __html: item.name }} />
                <BoxDesignation
                  dangerouslySetInnerHTML={{ __html: item.designation }}
                />
                <BoxText dangerouslySetInnerHTML={{ __html: item.text }} />

                {item.video_text && (
                  <VideoBox onClick={() => handleVideo(index)}>
                    <PlayIcon
                      className="lazyload"
                      data-src="https://static.percko.com/uploads/19d469eb-6651-40e3-ae5c-38ded39d720b.svg"
                    />
                    {item.video_text}
                  </VideoBox>
                )}
              </ExpertInfo>
            </ExpertBox>
          )
        )
      })}
    </ProductExpertWrapper>
  ) : null
}

const ExpertBox = styled.div`
  padding: 10px 0px;
  text-align: center;
  margin: 0px 10px;
  position: relative;
  min-height: 200px;
  max-width: 330px;
  width: 50%;

  @media (max-width: 550px) {
    width: 100%;
    max-width: 100%;
  }

  :nth-of-type(2) {
    @media (max-width: 550px) {
      min-height: 150px;
    }
  }
`

const ExpertImage = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 140px;
  height: 150px;

  @media (max-width: 480px) {
    width: 100px;
    height: auto;
  }
`

const Image = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: contain;
  border-radius: 5px;
  height: 150px;

  @media (max-width: 480px) {
    height: auto;
  }
`

const ExpertInfo = styled.div`
  position: absolute;
  left: 0px;
  top: 22px;
  right: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 6px;
  opacity: 0.95;
  padding: 15px;
  text-align: left;
  min-height: 127px;

  @media (max-width: 480px) {
    top: 15px;
    right: 40px;
    padding: 10px;
  }
`

const BoxTitle = styled.h5`
  color: #262626;
  font-size: 14px;
  line-height: 16px;
  font-family: "Gotham";
  font-weight: 500;
  margin: 0px;
`

const BoxDesignation = styled.h5`
  color: #262626;
  font-size: 14px;
  line-height: 16px;
  font-family: "Gotham HTF Book";
  font-weight: 500;
  font-style: normal;
  margin-bottom: 15px;
`

const BoxText = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-family: "Gotham HTF Book";
  font-weight: 500;
  font-style: normal;
`

const VideoBox = styled.a`
  font-size: 14px;
  line-height: 16px;
  font-family: "Gotham";
  font-weight: 500;
  text-decoration: underline;
  display: flex;
  margin-top: 15px;
  align-items: center;
  cursor: pointer;
`

const PlayIcon = styled.img`
  width: 25px;
  object-fit: contain;
  margin: 0px;
  margin-right: 10px;
`

const ProductExpertWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 0px;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`

export default ProductExpertSlider
