import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const TheyLoveUsBannerWrapper = styled.div`
  width: 100%;
  position: relative;
`

const DesktopBannerImage = styled.img`
  width: 100%;
  margin: 0px;

  @media (max-width: 550px) {
    display: none;
  }
`

const MobileBannerImage = styled.img`
  width: 100%;
  margin: 0px;
  display: none;

  @media (max-width: 550px) {
    display: block;
  }
`

const BannerContent = styled.div`
  width: 100%;
  position: absolute;
  max-width: 1080px;
  padding: 0px 20px;
  left: 0;
  right: 0;
  margin: 0px auto;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 550px) {
    position: relative;
    transform: translateX(0%);
    align-items: flex-start;
    padding: 20px;
    background: rgb(242, 242, 242);
  }
`

const BannerTitle = styled.h1`
  font-size: 36px;
  font-family: "Gotham Book";
  font-weight: bold;
  color: #262626;
  line-height: 1;
  margin-bottom: 20px;

  @media (max-width: 850px) {
    font-size: 24px;
    margin-bottom: 15px;
  }

  @media (max-width: 550px) {
    font-size: 22px;
    margin-bottom: 10px;
    line-height: 24px;
    font-family: "Gotham Book";
  }
`

const BannerText = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;

  @media (max-width: 850px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 550px) {
    font-size: 14px;
    line-height: 18px;
  }
`

export const TheyLoveUsBanner = ({ data }) => {
  return (
    <TheyLoveUsBannerWrapper>
      <DesktopBannerImage src={data.desktop_image} />
      <MobileBannerImage src={data.mobile_image} />
      <BannerContent>
        <div>
          {data.title && <BannerTitle>{data.title}</BannerTitle>}
          {data.subtitle && (
            <BannerText
              dangerouslySetInnerHTML={{
                __html: data.subtitle,
              }}
            />
          )}
        </div>
      </BannerContent>
    </TheyLoveUsBannerWrapper>
  )
}

export default injectIntl(TheyLoveUsBanner)
