import React, { useRef } from "react"
import "flickity/css/flickity.css"
import Left from "./assets/chevron-left.png"
import Right from "./assets/chevron-right.png"
import styled from "@emotion/styled"
const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null

const flickityOptions = {
  freeScroll: false,
  pageDots: false,
  wrapAround: true,
  prevNextButtons: true,
  adaptiveHeight: true,
}

const ClientSlider = ({ data }) => {
  var prevNext = useRef()
  const imageCounter = data => {
    let slider = []
    for (let i = 0; i < data.length; i = i + 3) {
      slider.push(
        <Row key={i.toString()}>
          {data[i] && (
            <Card>
              <ImageBox>
                <Image className="lazyload" data-src={data[i].image} alt="" />
              </ImageBox>
              <TextBox>
                <Title
                  dangerouslySetInnerHTML={{
                    __html: data[i].name,
                  }}
                />{" "}
                <SubText
                  dangerouslySetInnerHTML={{
                    __html: data[i].text,
                  }}
                />
              </TextBox>
            </Card>
          )}
          {data[i + 1] && (
            <Card>
              <ImageBox>
                <Image
                  className="lazyload"
                  data-src={data[i + 1].image}
                  alt=""
                />
              </ImageBox>
              <TextBox>
                <Title
                  dangerouslySetInnerHTML={{
                    __html: data[i + 1].name,
                  }}
                />{" "}
                <SubText
                  dangerouslySetInnerHTML={{
                    __html: data[i + 1].text,
                  }}
                />
              </TextBox>
            </Card>
          )}
          {data[i + 2] && (
            <Card>
              <ImageBox>
                <Image
                  className="lazyload"
                  data-src={data[i + 2].image}
                  alt=""
                />
              </ImageBox>
              <TextBox>
                <Title
                  dangerouslySetInnerHTML={{
                    __html: data[i + 2].name,
                  }}
                />{" "}
                <SubText
                  dangerouslySetInnerHTML={{
                    __html: data[i + 2].text,
                  }}
                />
              </TextBox>
            </Card>
          )}
        </Row>
      )
    }
    return slider
  }
  const mobileimageCounter = data => {
    let mobile_slider = []
    for (let i = 0; i < data.length; i = i + 1) {
      mobile_slider.push(
        <Row key={i.toString()}>
          {data[i] && (
            <Card>
              <ImageBox>
                <Image className="lazyload" data-src={data[i].image} alt="" />
              </ImageBox>
              <TextBox>
                <Title
                  dangerouslySetInnerHTML={{
                    __html: data[i].name,
                  }}
                />{" "}
                <SubText
                  dangerouslySetInnerHTML={{
                    __html: data[i].text,
                  }}
                />
              </TextBox>
            </Card>
          )}
          {/* {data[i + 1] && (
            <Card>
              <ImageBox>
                <Image
                  className="lazyload"
                  data-src={data[i + 1].image}
                  alt=""
                />
              </ImageBox>
              <TextBox>
                <Title
                  dangerouslySetInnerHTML={{
                    __html: data[i + 1].name,
                  }}
                />{" "}
                <SubText
                  dangerouslySetInnerHTML={{
                    __html: data[i + 1].text,
                  }}
                />
              </TextBox>
            </Card>
          )} */}
        </Row>
      )
    }
    return mobile_slider
  }
  return typeof window !== "undefined" ? (
    <Carousel className="carousel">
      <DesktopSlider style={{ width: "100%" }}>
        <Flickity
          flickityRef={c => (prevNext = c)}
          options={flickityOptions}
          className={"carousel"}
        >
          {imageCounter(data).map(item => item)}
        </Flickity>
      </DesktopSlider>
      <MobileSlider style={{ width: "100%" }}>
        <Flickity
          flickityRef={c => (prevNext = c)}
          options={flickityOptions}
          className={"carousel"}
        >
          {mobileimageCounter(data).map(item => item)}
        </Flickity>
      </MobileSlider>
    </Carousel>
  ) : null
}

const DesktopSlider = styled.div`
  width: 100%;
  display: block;

  @media (max-width: 767px) {
    display: none;
  }
`

const MobileSlider = styled.div`
  width: 100%;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  object-fit: contain;
  flex-direction: row;
  padding: 0px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const Card = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
`

const ImageBox = styled.div`
  max-width: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  object-fit: contain;
  margin-right: 10px;
`

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0;
`

const TextBox = styled.div`
  margin: 0;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  min-height: 110px;
  flex: 1;
`

const Title = styled.div`
  font-size: 13px;
  line-height: 1.3;
  font-family: "GothamLight";
  font-weight: bold;
  color: #5a7fc7;
  display: inline;
`

const SubText = styled.div`
  display: inline;
  font-family: "GothamLight";
  font-size: 13px;
  letter-spacing: 0;
  font-weight: bold;
`

const Carousel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px 0px 50px;
  max-width: 1080px;
  margin: 0px auto;

  @media (max-width: 550px) {
    padding: 0px;
    padding-bottom: 50px;
  }

  .carousel {
    padding: 0px 25px;
  }

  .flickity-slider {
    display: flex;
  }

  .flickity-prev-next-button {
    background-color: transparent;
    color: #111;
    width: 36px;
    height: 36px;
    border: 2px solid #111;

    :hover {
      background-color: #111;
      color: #fff;
    }
  }
  .flickity-prev-next-button.next {
    right: -15px;

    @media (max-width: 992px) {
      right: -15px;
    }
  }
  .flickity-prev-next-button.previous {
    left: -15px;

    @media (max-width: 992px) {
      left: -15px;
    }
  }
  .flickity-page-dots {
    bottom: -6px;
    max-width: 150px;
    margin: 0px auto;
    left: 0;
    right: 0;

    @media (max-width: 767px) {
      max-width: 130px;
    }
  }
  .flickity-page-dots .dot {
    margin: 0 5px;
  }
`

export default ClientSlider
